import { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn'; // Import Chinese locale for 星期 formatting

const Clock = () => {
    const [time, setTime] = useState('');
    let TIME_ZONE = '台灣時間';
    if (window?.location?.host?.indexOf('acecasino') > -1) {
        TIME_ZONE = '日本時間';
    }

    useEffect(() => {
        const updateClock = () => {
            setTime(
                moment().locale('zh-cn').format('YYYY/MM/DD dddd A hh:mm:ss')
            );
        };

        updateClock(); // Initialize time
        const interval = setInterval(updateClock, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <li className="nav-item">
            <p className="nav-link clock">
                {TIME_ZONE} : {time}
            </p>
        </li>
    );
};

export default Clock;
